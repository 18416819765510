<template>
    <OModal ref="createActivitiesFromSelObjectsDialog" >
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Create Activities") }}
                        </h4>
                        <div v-if="isExecutingProc" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Processing...') }}</span>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">
                    <div class="col-12">
                        <ODataGrid style="height:400px" 

                            :data-object="do_actsToBeCreated" :hideGridMenu="true" 
                            :showNewRecordsPanel="true" :disableFilterRow="true" :filter-row="false" ref="grid">
                                                                                
                            <OColumn field="OrgUnit" required :headerName='$t("Org.Unit")' editable width="180" sortable v-slot:editor="{modelValue: row}" bulkUpdate>
                                <OrgunitsLookup :bind="sel=>{row.OrgUnit_ID = sel.ID; row.OrgUnit = sel.Name; row.OrgUnitIdPath = sel.IdPath}">
                                        <template #orgunit>
                                            <input type="text" id="orgUnitInput" v-model="row.OrgUnit">
                                        </template>
                                </OrgunitsLookup>
                            </OColumn> 

                            <OColumn field="ActivityType" required editable bulkUpdate width="180" sortable :headerName='$t("Activity Type")' v-slot:editor="{modelValue: row}" :headerTitle='$t("Activity Type")'>
                                <ActivityTypesLookup 
                                    :bind="sel=>{row.ActivityType_ID = sel.ID; row.ActivityType = sel.Name;row.ActivityGroup_ID=sel.ActivityGroup_ID}" 
                                    :IdPath="row.OrgUnitIdPath" 
                                    textInput :textInputValue="row.ActivityType">
                                </ActivityTypesLookup>
                            </OColumn>

                            <OColumn field="Name" required editable bulkUpdate width="250" sortable :headerName='$t("Name")' :headerTitle='$t("Name")' />

                            <OColumn field="Checklist" editable bulkUpdate width="180" sortable :headerName='$t("Checklist")' v-slot:editor="{modelValue: row}" :headerTitle='$t("Checklist")'>
                                <ChecklistLookup 
                                    :bind="sel=>{
                                        row.Checklist_ID = sel.ID; 
                                        row.Checklist = sel.Name; 
                                        row.Discipline = sel.Discipline;
                                        row.Discipline_ID = sel.Discipline_ID;
                                        row.CompletionPhase = sel.CompletionPhase;
                                        row.CompletionPhase_ID = sel.CompletionPhase_ID;
                                        }" textInput 
                                    :IdPath="row.OrgUnitIdPath" 
                                    :ActivityGroupID="row.ActivityGroup_ID"
                                    :textInputValue="row.Checklist">
                                </ChecklistLookup>
                            </OColumn>

                            <OColumn field="Milestone" width="150" sortable :headerName='$t("Milestone")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Milestone")' hide>
                                <MilestonesLookup 
                                    :IdPath="row.OrgUnitIdPath" 
                                    :bind="sel=>{row.Milestone = sel.Name;row.Milestone_ID = sel.ID; }" textInput :textInputValue="row.Milestone">
                                </MilestonesLookup>                            
                            </OColumn>
    
                            <OColumn field="Shutdown" width="150" sortable :headerName='$t("Shutdown")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Shutdown")' hide>
                                <ShutdownsLookup 
                                    :OrgUnitId="row.OrgUnit_ID"
                                    :bind="sel=>{row.Shutdown = sel.Name;row.Shutdown_ID = sel.ID; }" textInput :textInputValue="row.Shutdown">
                                </ShutdownsLookup>                            
                            </OColumn>
    
                            <OColumn field="ProjectPhase" width="150" sortable :headerName='$t("Project Phase")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Project Phase")' hide>
                                <ProjectPhasesLookup 
                                    :OrgUnitId="row.OrgUnit_ID"
                                    :bind="sel=>{row.ProjectPhase = sel.Name;row.ProjectPhase_ID = sel.ID; }" textInput :textInputValue="row.ProjectPhase">
                                </ProjectPhasesLookup>                            
                            </OColumn>

                            <OColumn field="ConstructionPhase" width="150" sortable :headerName='$t("Construction Phase")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Construction Phase")' hide>
                                <ConstructionPhasesLookup 
                                    :OrgUnitId="row.OrgUnit_ID"
                                    :bind="sel=>{row.ConstructionPhase = sel.Name;row.ConstructionPhase_ID = sel.ID; }" textInput :textInputValue="row.ConstructionPhase">
                                </ConstructionPhasesLookup>                            
                            </OColumn>

                            <OColumn field="MainArea" width="150" sortable :headerName='$t("Main Area")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Main Area")' hide>
                                <MainAreasLookup 
                                    :IdPath="row.OrgUnitIdPath" 
                                    :bind="sel=>{row.MainArea = sel.Name;row.MainArea_ID = sel.ID; }" textInput :textInputValue="row.MainArea">
                                </MainAreasLookup>                            
                            </OColumn>

                            <OColumn field="Discipline" width="150" sortable :headerName='$t("Discipline")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Discipline")' hide>
                                <DisciplinesLookup 
                                    :IdPath="row.OrgUnitIdPath" 
                                    :bind="sel=>{row.Discipline = sel.NameAndTitle;row.Discipline_ID = sel.ID; }" textInput :textInputValue="row.Discipline">
                                </DisciplinesLookup>                            
                            </OColumn>
                            
                            <OColumn field="SubProject" width="150" sortable :headerName='$t("SubProject")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("SubProject")' hide>
                                <SubProjectsLookup 
                                    :OrgUnitId="row.OrgUnit_ID"
                                    :bind="sel=>{row.SubProject = sel.NameAndTitle;row.SubProject_ID = sel.ID; }" textInput :textInputValue="row.SubProject">
                                </SubProjectsLookup>                            
                            </OColumn>

                            <OColumn field="Package" width="150" sortable :headerName='$t("Package")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Package")' hide>
                                <PackageLookup 
                                    :OrgUnitId="row.OrgUnit_ID"
                                    :bind="sel=>{row.Package = sel.NameAndTitle;row.Package_ID = sel.ID; }" textInput :textInputValue="row.Package">
                                </PackageLookup>                            
                            </OColumn>

                            <OColumn field="HandoverType" width="150" sortable :headerName='$t("Handover Type")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Handover Type")' hide>
                                <HandoverTypesLookup 
                                    :IdPath="row.OrgUnitIdPath" 
                                    :bind="sel=>{
                                            row.HandoverType_ID = sel.ID;
                                            row.HandoverType = sel.Name;
                                            row.Checklist=sel.Checklist;
                                            row.Checklist_ID=sel.Checklist_ID;
                                            row.Revision=sel.Revision;
                                            row.CompletionPhase_ID=sel.CompletionPhase_ID;
                                            row.CompletionPhase=sel.CompletionPhase; 
                                            row.ActivityType_ID=sel.ActivityType_ID; 
                                            row.ActivityType = sel.ActivityType}" textInput :textInputValue="row.HandoverType">
                                </HandoverTypesLookup>                           
                            </OColumn>

                            <OColumn field="CompletionPhase" width="150" sortable :headerName='$t("Completion Phase")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Completion Phase")' hide>
                                <CompletionPhasesLookup 
                                    :OrgUnitId="row.OrgUnit_ID"
                                    :bind="sel=>{row.CompletionPhase = sel.Name;row.CompletionPhase_ID = sel.ID; }" textInput :textInputValue="row.CompletionPhase">
                                </CompletionPhasesLookup>                            
                            </OColumn>

                            <OColumn field="ActivityMeta1" width="150" sortable :headerName='$t("Activity Meta 1")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Activity Meta 1")' hide>
                                <MetaObjectLookup :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.ActivityMeta1 = sel.Name; }" MetaField="ActivityMeta1" textInput :textInputValue="row.ActivityMeta1" />
                            </OColumn>

                            <OColumn field="ActivityMeta2" width="150" sortable :headerName='$t("Activity Meta 2")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Activity Meta 2")' hide>
                                <MetaObjectLookup :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.ActivityMeta2 = sel.Name; }" MetaField="ActivityMeta2" textInput :textInputValue="row.ActivityMeta2" />
                            </OColumn>
                            
                            <OColumn field="Frequency" width="150" sortable :headerName='$t("Frequency")' v-slot:editor="{modelValue: row}" editable bulkUpdate :headerTitle='$t("Frequency")' hide>
                                <ChecklistFrequenciesLookup :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.Frequency = sel.Name; row.RepeatFrequencyNumberOfDays=sel.NumberOfDays; }" textInput :textInputValue="row.Frequency" />
                            </OColumn>
                        </ODataGrid>
                    </div>
                    <div class="col-12" v-if="props.objectsDataObject">
                        <div class="radio">
                            <label class=""><input type="radio" v-model="actSetting" value=false name="optActivityGen" checked>{{$t("Create Activities with all objects")}}</label>
                        </div>
                        <div class="radio">
                            <label class=""><input type="radio" v-model="actSetting" value=true name="optActivityGen">{{$t("Create activities for each object")}}</label>
                        </div>   
                    </div> 
                    <div class="col-12">
                        <label class="">
                        <input v-model="inclObjectName" class="form-check-input" type="checkbox">
                            {{$t("Include Object Name in Activity Name") }}
                        </label>
                    </div>
                    <div class="col-12">
                        <label class="">
                        <input v-model="inclObjectDesc" class="form-check-input" type="checkbox">
                            {{$t("Include Object Description in Activity Name")}}
                        </label>
                    </div>
                </div>
                            
                <div class="modal-footer">
                    <div style="display:inline;font-size:small;" class="me-2" v-if="selectedObjectsCount===0">
                        <div class="text-danger">{{ $t("No objects selected") }}</div>
                    </div>
                    <ODataLookup 
                        :forceReloadOnOpen="true"
                        :dataObject="do_activitySets"
                        :bind="addActivitySet"
                        :whereClause="getWhereClause"
                        noClear
                    >
                        <OColumn field="Name" width="400"></OColumn>

                        <template #target="{ target }">
                            <button class="btn btn btn-outline-primary dropdown-toggle" :ref="target">
                                {{ $t("Add Activity Set")}}
                            </button>
                        </template>
                    </ODataLookup>   
                    <button class="btn btn-outline-primary" :disabled="selectedObjectsCount===0 || isExecutingProc" @click="createActs">{{$t("Create Activities")}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import alert from 'o365.controls.alert.ts';
import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
import { ref, defineExpose, computed, watch } from 'vue';
import OModal from 'o365.vue.components.Modal.vue'; 
import { userSession } from 'o365.modules.configs.ts'; 
import OrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue'
import SubProjectsLookup from "complete.vue.components.SubProjectsLookup.vue";
import ConstructionPhasesLookup from "complete.vue.components.ConstructionPhasesLookup.vue";
import ShutdownsLookup from "complete.vue.components.ShutdownsLookup.vue";
import MilestonesLookup from "complete.vue.components.MilestonesLookup.vue";
import PackageLookup from "complete.vue.components.PackageLookup.vue";
import ActivityTypesLookup from "complete.vue.components.ActivityTypesLookup.vue";
import ChecklistLookup from "check.vue.components.ChecklistLookup.vue";
import DisciplinesLookup from "assets.vue.components.DisciplinesLookup.vue";
import ProjectPhasesLookup from "complete.vue.components.ProjectPhasesLookup.vue";
import MainAreasLookup from "assets.vue.components.MainAreasLookup.vue";
import HandoverTypesLookup from "complete.vue.components.HandoverTypesLookup.vue";
import CompletionPhasesLookup from "complete.vue.components.CompletionPhasesLookup.vue";
import MetaObjectLookup from "assets.vue.components.MetaObjectLookup.vue";
import ChecklistFrequenciesLookup from "check.vue.components.ChecklistFrequenciesLookup.vue";
import context from "o365.modules.Context.ts";

const emit = defineEmits(["ActivitiesCreated"]);
const OrgUnit_ID = ref();
const OrgUnit = ref();
const SubProject = ref();
const SubProject_ID = ref();
const Shutdown_ID = ref();
const Shutdown = ref();
const ConstructionPhase_ID = ref();
const ConstructionPhase = ref();
const Milestone_ID = ref();
const Milestone = ref();
const Package_ID = ref();
const Package = ref(); 
const procSubmit = getOrCreateProcedure({ id: "procSubmit" + crypto.randomUUID(), procedureName: "astp_Complete_CreateActivitiesFromObjects" });
const procAddActSet = getOrCreateProcedure({ id: "procAddActSet" + crypto.randomUUID(), procedureName: "astp_Complete_ActivitiesAddActSetToActsToBeCreated" });
const createActivitiesFromSelObjectsDialog = ref();
const isExecutingProc = ref(false);
const inclObjectName = ref(false); 
const inclObjectDesc = ref(false); 
const actSetting = ref(false); 
const useOrgUnitFromObj = ref(false); 
const desc = ref();

async function createActs() {
    const preparedRows = [];
    if (props.objectsDataObject?.selectionControl?.selectedRows?.length>0) {
        const ids = await props.objectsDataObject.selectionControl.getSelectedRows();
        ids.forEach(sel => {
            if (sel.ID !== null) preparedRows.push([sel.ID]);
        });
        createActivities(preparedRows);
    }
    else if (props.objectID) {
        preparedRows.push([props.objectID]);
        createActivities(preparedRows);
    }
}

defineExpose({ show });

async function addActivitySet(row) {
    isExecutingProc.value = true;
    await procAddActSet.execute({
        ActivitySet_ID: row.ID,
        OrgUnit_ID: context.id
    }).then(
        (response, err) => {  
            do_actsToBeCreated.load();
        }
    ).finally(() => {
        isExecutingProc.value = false;
    });    
}

const do_actsToBeCreated = getOrCreateDataObject({
    id: 'do_actsToBeCreatedFromObjectsDialog',
    viewName: 'aviw_Complete_ActivitiesToBeCreated',
    uniqueTable: 'atbv_Complete_ActivitiesToBeCreated',    
    distinctRows: false,
    maxRecords: 100,
    allowInsert: true,
    allowUpdate: true,
    allowDelete: true,
    disableLayouts: false,
    fields: [
        { name: "PrimKey", type: "string" },
        { name: "ID", type: "Number" },
        { name: "Created", type: "string" },
        { name: "CreatedBy_ID", type: "Number" },
        { name: "Updated", type: "string" },
        { name: "UpdatedBy_ID", type: "Number" },
        { name: "Name", type: "string" },
        { name: "OrgUnit_ID", type: "Number" },
        { name: "ActivityType_ID", type: "Number" },
        { name: "ActivityGroup_ID", type: "Number" },
        { name: "Checklist_ID", type: "Number" },
        { name: "Milestone_ID", type: "Number" },
        { name: "Shutdown_ID", type: "Number" },
        { name: "ProjectPhase_ID", type: "Number" },
        { name: "ConstructionPhase_ID", type: "Number" },
        { name: "MainArea_ID", type: "Number" },
        { name: "Discipline_ID", type: "Number" },
        { name: "SubProject_ID", type: "Number" },
        { name: "Package_ID", type: "Number" },
        { name: "OrgUnit", type: "string" },
        { name: "ActivityType", type: "string" },
        { name: "Checklist", type: "string" },
        { name: "Milestone", type: "string" },
        { name: "Shutdown", type: "string" },
        { name: "ProjectPhase", type: "string" },
        { name: "ConstructionPhase", type: "string" },
        { name: "MainArea", type: "string" },
        { name: "Discipline", type: "string" },
        { name: "SubProject", type: "string" },
        { name: "Package", type: "string" },
        { name: "OrgUnitIdPath", type: "string" },
        { name: "RepeatFrequencyNumberOfDays", type: "Number" },
        { name: "Frequency", type: "string" },
        { name: "HandoverType_ID", type: "Number" },
        { name: "HandoverType", type: "string" },
        { name: "CompletionPhase_ID", type: "Number" },
        { name: "CompletionPhase", type: "string" },
        { name: "IdPath", type: "string" },
        { name: "ActivityMeta1", type: "string" },
        { name: "ActivityMeta2", type: "string" }
    ]
});

const getWhereClause = computed( () => {
    return "'" + context.idPath + "' LIKE PublishedToIdPath + '%'";
});
 

const do_activitySets = getOrCreateDataObject({
    id: 'do_activitySets',
    viewName: 'aviw_Complete_ActivitySetsForLookup',
    distinctRows: false,
    maxRecords: 100,
    allowInsert: false,
    allowUpdate: false,
    allowDelete: false,
    disableLayouts: false,
    fields: [
        { name: "ID", type: "int" },
        { name: "Name", type: "string" }
    ]
});

const props = defineProps({
    objectsDataObject: {
        type: Object,
        required: false
    },
    objectID: "Number"
});

const selectedObjectsCount = computed(() => props.objectsDataObject?.selectionControl?.selectedRows?.length);

async function show() { 
    createActivitiesFromSelObjectsDialog.value.show();
    do_actsToBeCreated.load();
}

async function createActivities(ids) {
    isExecutingProc.value = true;
    await procSubmit.execute({
        Objects: ids,
        CreateActivitisForEachObject: actSetting.value,
        IncludeObjectName: inclObjectName.value,
        IncludeObjectDescription: inclObjectDesc.value
    }).then(
        (response, err) => {  
            refreshSelectedRows();
            alert($t("Activities Created:"), 'success', {autohide: true, delay: 3000});
            createActivitiesFromSelObjectsDialog.value.hide();            
        }
    ).finally(() => {
        isExecutingProc.value = false;
    });    
}

async function refreshSelectedRows() {
    const ids = await props.objectsDataObject.selectionControl.getSelectedRows();
    ids.forEach(sel => {
        if (sel.index !== null) {
            if (props.objectsDataObject) props.objectsDataObject.refreshRow(sel.index);
        } 
    });
}

</script>